/** @jsx jsx */
import React from "react";
import { jsx, Container, Box } from "theme-ui";
import { graphql } from "gatsby";
import Layout from "../../../components/Layout";
import ExpectationAndPackages from "../../../components/shared/ExpectationAndPackages";
import HeroSection from "../../../components/sections/heroSection";
import "./../../static/style.less";
import SEO from "../../../components/seo";

class ExpectationsHome extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { sections, bannerData, seoMetadata } = data.allContentfulPage.nodes && data.allContentfulPage.nodes.length > 0 ? data.allContentfulPage.nodes[0] : [];
    return (
      <Layout bannerData={bannerData}>
         {seoMetadata && (
        <SEO
          title={seoMetadata.title}
          description={seoMetadata.description}
        />
      )}
        {sections && sections.length > 0 && <Box mb={[16, 32]}>
            <HeroSection sectionContent={sections[0]} />
        </Box>}
        <Box sx={{pt:"24px", pb:"70px", backgroundColor: '#F4F4F7'}}>
          <Container>
            <ExpectationAndPackages pageType='expectations' expectationContent={sections[1].content} packagesContent={sections[2].content} version='v1'/>
          </Container>
        </Box>
        {sections && sections.length > 0 && <Box>
            <HeroSection sectionContent={sections[3]}/>
        </Box>}
      </Layout>
    );
  }
}
export default ExpectationsHome;
export const pageQuery = graphql`
query expecPage {
  allContentfulPage(filter: {slug: {eq: "expectations"}}) {
    nodes {
      id
      seoMetadata {
        description
        keywords
        ogImage {
          gatsbyImageData
          title
        }
        title
      }
      sections {
        ... on ContentfulHeroSection {
          id
          h1Heading
          h2Heading
          h3Heading
          h4Heading
          imageContainerWidth
          textContainerWidth
          classname
          content {
            raw
            references {
              ... on ContentfulAsset {
                contentful_id
                __typename
                gatsbyImageData
                title
                sys {
                  type
                }
              }
            }
          }
          fontSize
          backgroundColor
          imageWidth
          textAlign
          fontColor
          imagePosition
          displayImageOnMobile
          image {
            gatsbyImageData
            file {
              contentType
              url
            }
            description
          }
          statsBlock {
            heading
            description
          }
          scrollingImages {
            altText
            id
            imageTitle
            image {
              gatsbyImageData(width:150)
            }
          }
          backgroundImage {
            gatsbyImageData
            id
            title
          }
          buttons {
            actionEvent
            fullWidth
            icon
            iconSize
            id
            style
            label
            url
          }
        }
        ... on ContentfulRichTextSection {
          id
          content {
            raw
          }
        }
      }
      bannerData {
        bannerImage {
          gatsbyImageData(width:30)
        }
        bannerSlug
        bannerText {
          raw
        }
        label
        link
      }
    }
  }
}
`;
